<template>
  <div class="test-page page1" v-allow:auth="'dashboard.read'">
    <div class="flex items-center justify-center py-24" v-if="!dashboardLoaded">
      <Loader />
    </div>
    <div>
      <div class="flex flex-row mb-2.5" v-if="dashboardLoaded">
        <SubHeader
          :backButton="true"
          backRedirectionUrl="/bi-dashboard-view"
          :customCrumbLists="customBreadCrumbs"
        />
      </div>

      <div class="flex mb-2 gap-4 items-center" v-if="dashboardLoaded">
        <!-- <list-icon class="h-6 w-6" />
            <h3 class=""> Filter </h3> -->
        <div
          class="flex mb-4 gap-4 items-center"
          v-if="!dashboardUrl && dashboardLoaded"
        >
          <dropdown
            reposition
            searchable
            class="filter-dropdown h-10"
            placeholder="Users"
            :disabled="!checkPermission('users.read')"
            :options="allUserOptions"
            v-model="globalUsers"
            :multiple="true"
            :taggable="false"
            :colors="{
              text: '#282828',
              bg: 'white',
              svg: '#282828',
              border: '#e8e8e8',
            }"
            @input="filterDashboard"
            :limit="1"
            units="Users"
            width="192px"
            minWidth="102px"
            :config="{ label: 'user_name', trackBy: 'id' }"
          >
            <template #caret>
              <sort-icon />
            </template>
          </dropdown>

          <dropdown
            reposition
            searchable
            class="filter-dropdown h-10"
            placeholder="Groups"
            :disabled="!checkPermission('groups.read')"
            :options="allTeamOptions"
            v-model="globalGroups"
            :multiple="true"
            :taggable="false"
            :colors="{
              text: '#282828',
              bg: 'white',
              svg: '#282828',
              border: '#e8e8e8',
            }"
            @input="filterDashboard"
            :limit="1"
            units="Groups"
            width="192px"
            minWidth="102px"
            :config="{ label: 'title', trackBy: 'id' }"
          >
            <template #caret>
              <sort-icon />
            </template>
          </dropdown>
          <dropdown
            reposition
            searchable
            class="filter-dropdown h-10"
            placeholder="Clients"
            :disabled="!checkPermission('client.read')"
            :options="allClientOptions"
            v-model="globalClients"
            :multiple="true"
            :taggable="false"
            :colors="{
              text: '#282828',
              bg: 'white',
              svg: '#282828',
              border: '#e8e8e8',
            }"
            @input="filterDashboard"
            :limit="1"
            units="Clients"
            width="192px"
            minWidth="102px"
            :config="{ label: 'name', trackBy: 'id' }"
          >
            <template #caret>
              <sort-icon />
            </template>
          </dropdown>
        </div>
        <!-- <dropdown
          reposition
          searchable
          class="filter-dropdown h-10 ml-auto"
          placeholder="Dashboards"
          :options="dashboardOptions"
          v-model="selectedDashboard"
          :colors="{
            text: '#282828',
            bg: 'white',
            svg: '#282828',
            border: '#e8e8e8',
          }"
          :limit="1"
          width="192px"
          minWidth="102px"
          :config="{ label: 'labelName', trackBy: 'dashboard_id' }"
          @input="selectDashboard"
        >
          <template #caret>
            <sort-icon />
          </template>
        </dropdown> -->
      </div>

      <div v-if="!dashboardUrl && dashboardLoaded">
        <div
          class="flex max-w-full overflow-x-auto gap-4 lg:gap-6 card-panel scroll-bar"
        >
          <div
            class="flex items-center justify-center py-24 bg-base-100 rounded-xl w-full"
            v-if="caseCountLoader"
          >
            <Loader />
          </div>
          <template v-else>
            <card
              @select="selectCard(card)"
              v-for="card in caseStatusCard"
              :key="card.title"
              :title="card.title"
              :value="card.value"
              :color="card.color"
              :class="{
                'card--selected':
                  selectedCard && selectedCard.title === card.title,
              }"
            />
          </template>
        </div>
        <div class="wrapper p-4 mb-6 content">
          <!-- // loader starts here  -->
          <div class="wrapper wrapper--row justify-between" v-if="cardData">
            <chart
              class="chart"
              :name="cardData.chart.name"
              :data="cardData.chart.data"
            />
            <test-table
              class="table"
              :title="caseEventDetails.name"
              :subtitle="cardData.table.subtitle"
              :data="caseEventDetails.data"
              colorHeader="#0D69D5"
              @select="handleRowClick"
              ref="scrollContainer"
            />
          </div>

          <div
            v-else
            class="empty-data text-lg flex bg-base-100 justify-center items-center py-32 rounded-xl text-center"
          >
            <Loader />
          </div>

          <sidelegend
            v-model="isOpened"
            v-if="cardData && cardData.sideLegend"
            :list="cardData.sideLegend"
          />
        </div>
        <div>
          <tabs
            @select="selectTab"
            :selected="fakeData.tabs.selected"
            :tabs="fakeData.tabs.list"
          >
            <template>
              <div class="dropdown-menu">
                <!-- <span>Customize Table</span>
            <svg
            width="16"
            height="11"
            viewBox="0 0 16 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            >
            <path
                d="M6.93934 6.93934L5.87868 8L8 10.1213L9.06066 9.06066L6.93934 6.93934ZM15.0607 3.06066C15.6464 2.47487 15.6464 1.52513 15.0607 0.93934C14.4749 0.353553 13.5251 0.353553 12.9393 0.93934L15.0607 3.06066ZM9.06066 9.06066L15.0607 3.06066L12.9393 0.93934L6.93934 6.93934L9.06066 9.06066Z"
                fill="white"
            />
            <path
                d="M6.93934 9.06066L8 10.1213L10.1213 8L9.06066 6.93934L6.93934 9.06066ZM3.06066 0.939339C2.47487 0.353553 1.52513 0.353553 0.939339 0.93934C0.353553 1.52513 0.353552 2.47487 0.939339 3.06066L3.06066 0.939339ZM9.06066 6.93934L3.06066 0.939339L0.939339 3.06066L6.93934 9.06066L9.06066 6.93934Z"
                fill="white"
            />
            </svg> -->
              </div>
            </template>
            <template #content-1>
              <test-table-large
                id="candidate"
                :key="fakeData.tabs.selected + candidatesTotalPage.total"
                :totalRows="candidatesTotalPage.total"
                :isLoading="isLoading"
                :columns="tableLargeData.candidateView.columns"
                :rows="tableLargeData.candidateView.rows"
                :sortingEnabled="true"
                :filterOptions="columnFilterOptions.candidate"
                :filterOptionsAsync="columnFilterOptionsAsync.candidate"
                @handleStatusClick="handleStatusClick"
                :pagination-options="getPaginationOptions"
                :filters="columnFilters.candidate"
                @page-change="onPageChange($event, 'candidate')"
                @per-page-change="onPageChange($event, 'candidate', true)"
                @sort-change="onSortChange($event, 'candidate')"
                @column-filter="onColumnFilters($event, 'candidate')"
                @search-filter="getSearchOptionsTick($event, 'candidate')"
                @filters-clear="clearFilters('candidate')"
                :config="{
                  'select-options': {
                    enabled:
                      checkPermission('case.allocate') &&
                      checkPermission('users.read') &&
                      checkPermission('groups.read'),
                    selectOnCheckboxOnly: true,
                    selectionText: 'case(s) selected',
                  },
                }"
                @on-work-allocation="onWorkAllocation($event)"
                @on-case-delete="onCaseDelete($event)"
                @on-allocation="onAllocation($event, 'candidate')"
              >
                <template #link="{ customData }">
                  <!-- {{ item.customData }} -->
                  <!-- {{case_data.case_reference_id}} -->
                  <template v-if="customData.automated">
                    <router-link
                      :to="customData.link"
                      class="text-primary whitespace-nowrap cursor-pointer hover:text-primary-focus"
                    >
                      {{ customData.case_id }}
                    </router-link>
                  </template>
                  <template v-else>
                    <!-- <router-link
                                    :to="{ 
                                        name: 'Profile Container',
                                        params: { caseid: customData.case_id },
                                        query: { caseid: customData.id || customData?.case_uuid },         
                                    }" 
                                    class="text-primary whitespace-nowrap cursor-pointer hover:text-primary-focus">
                                    {{ customData.case_id }}
                                </router-link> -->
                    <div
                      @click="handleCaseClick(customData, 'case')"
                      class="text-primary whitespace-nowrap cursor-pointer hover:text-primary-focus"
                    >
                      {{ customData.case_id }}
                    </div>
                  </template>
                </template>
              </test-table-large>
            </template>
            <template #content-2>
              <test-table-large
                id="check"
                :key="fakeData.tabs.selected + checksTotalPages.total"
                :isLoading="isLoadingChecks"
                :totalRows="checksTotalPages.total"
                :columns="tableLargeData.checkView.columns"
                :rows="filteredData.checksList"
                :sortingEnabled="true"
                :filterOptions="columnFilterOptions.check"
                :filterOptionsAsync="columnFilterOptionsAsync.check"
                :pagination-options="getPaginationOptions"
                :filters="columnFilters.check"
                @page-change="onPageChange($event, 'check')"
                @per-page-change="onPageChange($event, 'check', true)"
                @sort-change="onSortChange($event, 'check')"
                @column-filter="onColumnFilters($event, 'check')"
                @search-filter="getSearchOptionsTick($event, 'check')"
                @filters-clear="clearFilters('check')"
              >
                <template #link="{ customData }">
                  <!-- {{ item.customData }} -->
                  <!-- {{case_data.case_reference_id}} -->
                  <!-- <router-link 
                                :to="{
                                    name: 'Profile Container',
                                    params: { caseid: customData.case_id },
                                    query: { 
                                        caseid: customData.case_uuid, 
                                        check: customData.tenant_check_id, 
                                        type: customData && customData.case_reference_id ? 'Reference' : 'Non Reference' 
                                    },
                                }"
                                class="text-primary whitespace-nowrap cursor-pointer hover:text-primary-focus">
                                {{ customData.case_id }}
                            </router-link> -->
                  <div
                    @click="handleCaseClick(customData, 'check')"
                    class="text-primary whitespace-nowrap cursor-pointer hover:text-primary-focus"
                  >
                    {{ customData.case_id }}
                  </div>
                </template>
              </test-table-large>
            </template>
            <template #content-3>
              <test-table-large
                id="task"
                :key="fakeData.tabs.selected + tasksTotalPages.total"
                :isLoading="loadingStates.taskView"
                :totalRows="tasksTotalPages.total"
                :columns="tableLargeData.taskView.columns"
                :rows="filteredData.taskViewList"
                :sortingEnabled="true"
                :filterOptions="columnFilterOptions.task"
                :filterOptionsAsync="columnFilterOptionsAsync.task"
                :pagination-options="getPaginationOptions"
                :filters="columnFilters.task"
                @page-change="onPageChange($event, 'task')"
                @per-page-change="onPageChange($event, 'task', true)"
                @sort-change="onSortChange($event, 'task')"
                @column-filter="onColumnFilters($event, 'task')"
                @filters-clear="clearFilters('task')"
                @search-filter="getSearchOptionsTick($event, 'task')"
                @on-allocation="onAllocation($event, 'task')"
                :due_date="due_date"
              >
                <template #link="customData">
                  <!-- <router-link
                                :to="{
                                    name: 'Profile Container',
                                    params: { caseid: customData.customData.visible_case_id },
                                    query: { caseid: customData.customData.case_id, action: 'history' },
                                    hash: `#${customData.customData.object_id}`,
                                }"
                                class="text-primary whitespace-nowrap cursor-pointer hover:text-primary-focus"
                                >{{ customData.customData.visible_case_id }}</router-link
                            > -->
                  <div
                    @click="handleCaseClick(customData, 'task')"
                    class="text-primary whitespace-nowrap cursor-pointer hover:text-primary-focus"
                  >
                    {{ customData.customData.visible_case_id }}
                  </div>
                </template>
                <template #rich_text="customData">
                  {{ checkData(customData) }}
                </template>
              </test-table-large>
            </template>
          </tabs>
        </div>

        <ModalContent
          ref="status-modal"
          name="status-modal"
          :title="statusModalTitle"
          submitButtonText="Close"
          @close="handleCloseStatusModal"
          :showFooter="false"
          :width="'90%'"
        >
          <template #content>
            <div
              class="table-overflow overflow-x-auto mb-4 bg-gray-200 rounded-2xl"
            >
              <!-- <table class="w-full">
                <thead>
                  <th
                    class="py-3 border-b border-solid border-gray-400 text-base font-medium"
                    :class="[i === 0 ? '' : 'border-l']"
                    v-for="(col, i) in statusModalTable.columns"
                    :key="col.id"
                  >
                    {{ col.name }}
                  </th>
                </thead>
                <tbody class="">
                  <tr class="">
                    <td
                      class="py-3 text-center border-solid border-gray-400 text-gray-600"
                      :class="[index === 0 ? '' : 'border-l']"
                      v-for="(row, i, index) in statusModalTable.row"
                      :key="i"
                    >
                      {{ row }}
                    </td>
                  </tr>
                </tbody>
              </table> -->
            </div>
            <Timeline :data="timelineData" />
            <!-- <template v-if="checksTimeline && checksTimeline.length">
              <div
                class=""
                v-for="timeline in checksTimeline"
                :key="`${timeline.header.label}__`"
              >
                <Timeline
                  :data="timeline"
                  type="checks"
                  class="bg-card-bg rounded-xl"
                  :loading="isTimelineLoading"
                />
              </div>
            </template> -->
            <!-- <div
          class="
            alert
            flex
            justify-center
            max-w-lg
            mx-auto
            alert-info
            text-center
            my-8
          "
          v-else
        >
          No Reference check data
        </div> -->
          </template>
        </ModalContent>
        <WorkAllocation
          :loadingStates="loadingStates"
          :allTeams="teamsStates"
          :allUsers="usersStates"
          @onWorkAllocateChange="onWorkAllocateChange($event)"
          @on-save="onWorkAllocationSave($event)"
          @getCandidateViews="getCandidateViews"
          @on-reset="onAllocationReset($event)"
        />
        <AllocationList
          :isLoading="loadingStates.getAllocation"
          :allocatedResources="allocatedResources"
          @removeAllocation="removeAllocation($event)"
        />
      </div>
    </div>
    <div
      class="h-screen overflow-hidden"
      v-if="dashboardUrl && dashboardLoaded"
    >
      <iframe
        :src="dashboardUrl"
        id="dashboard-frame"
        width="100%"
        height="90%"
        @load="adjustIframeHeight"
        allowfullscreen
        frameborder="0"
      ></iframe>
    </div>
  </div>
</template>

<script>
import card from "@shared/dashboard-components/card";
import chart from "@/components/dashboard-components/chart";
import table from "@shared/dashboard-components/table";
import tableLarge from "@shared/dashboard-components/table-large";
import sidelegend from "@shared/dashboard-components/sidelegend";
import tabs from "@/components/dashboard-components/tabs";
import dropdown from "@/components/dropdown-base";
import sortIcon from "@/assets/icons/dashboard/dropdown-double.svg";
import axios from "@/axios";
import ModalContent from "@/components/modal-content";
import Timeline from "@shared/timeline";
import { mapGetters, mapMutations } from "vuex";
import { DateTime } from "luxon";
import Loader from "@/components/loader";
import { onlyUnique } from "@/plugins/utils.js";
import { uuid } from "vue-uuid";
import { uniqBy } from "lodash";
const WorkAllocation = () => import("./components/work-allocation.vue");
const AllocationList = () => import("./components/allocation-list.vue");
import { checkPermission } from "@shared/utils/functions";
// import listIcon from "@/assets/icons/svg/list.svg"
import { FormatDateNew } from "@/plugins/functions";
import SubHeader from "@/components/SubHeader";
export default {
  name: "case-dashboard",
  components: {
    card,
    chart,
    "test-table": table,
    "test-table-large": tableLarge,
    sidelegend,
    tabs,
    dropdown,
    ModalContent,
    Timeline,
    Loader,
    WorkAllocation,
    AllocationList,
    // listIcon,
    sortIcon,
    SubHeader,
  },
  title: "Case Dashboard",
  data() {
    return {
      selectedTileFilter: [],
      selectedCard: null,
      isOpened: false,
      fakeData: {
        cards: [],
        tabs: {
          selected: 1,
          list: [
            {
              id: 1,
              label: "Subject View",
              key: "candidate",
            },
            {
              id: 2,
              label: "Check View",
              key: "check",
            },
            {
              id: 3,
              label: "Task View",
              key: "task",
            },
          ],
        },
      },
      timelineData: {
        header: {
          label: "Subject Form Status",
          icon: "#",
        },
        timeline: [],
      },
      pagination: {
        candidateView: {
          page: 1,
          perPage: 10,
          total_page: 1,
        },
      },
      paginationOptions: {
        enabled: true,
        mode: "remote",
        dropdownAllowAll: false,
      },
      candidateViewList: [],
      candidateViewParams: {},
      candidatesTotalPage: 0,
      checkViewList: [],
      checksTotalPages: [],
      tasksTotalPages: [],
      isLoading: false,
      serverParams: {
        check: {
          page: 1,
          perPage: 10,
        },
        candidate: {
          page: 1,
          perPage: 10,
        },
        task: {
          page: 1,
          perPage: 10,
        },
      },
      menuOptions: [],
      columnFilters: {
        candidate: null,
        check: null,
        task: null,
      },
      caseCount: [],
      caseCountLoader: false,
      caseStatusCard: [],
      caseEventDetails: {},
      statusModalTitle: "",
      statusModalTable: {
        columns: [
          { id: 0, name: "First Name" },
          { id: 1, name: "Surname" },
          { id: 2, name: "Phone No" },
          { id: 3, name: "Email Id" },
          // {id: 4, name: 'DOB'},
          // {id: 5, name: 'NI No'},
          { id: 6, name: "Days So Far" },
          { id: 7, name: "Days Remaining" },
        ],
        row: {
          candidate_first_name: "",
          candidate_last_name: "",
          candidate_phone: "",
          candidate_email: "",
          days_so_far: "",
          days_remaining: "",
        },
      },
      searchLoading: {
        candidate: {},
        check: {},
        task: {},
      },
      searchTimeout: null,
      columnFilterOptionsAsync: {
        candidate: null,
        check: null,
        task: null,
      },
      checksTimeline: [],
      allCaseStatusOptions: [],
      allCheckStatusOptions: [],
      allTeams: [],
      allUsers: [],
      allClients: [],
      globalUsers: [],
      globalGroups: [],
      globalClients: [],
      loadingStates: {
        teams: false,
        users: false,
        clients: false,
        getAllocation: false,
        taskView: false,
      },
      allocatedResources: [],
      allFormStatusOptions: [],
      teamsStates: [],
      usersStates: [],
      taskViewList: [],
      sortParams: null,
      due_date: "",
      dashboardUrl: "",
      dashboardLoaded: false,
      dashboardOptions: [],
      selectedDashboard: null,
      baseUrl: "http://18.132.243.69/bi/site/site1/dashboards/",
      dashboardId: `5485053a-c2f0-4c2c-aa9f-35a30092e07f`,
      categoryName: "Category 01",
      dashboardName: "Form Status",
    };
  },
  async created() {
    // TODO change to selection of the first card from API:
    // this.selectCard(this.fakeData.cards[0]);
  },
  async mounted() {
    await this.getTenantDashboards();
    if (this.$route.path == "/default-dashboard") {
      this.dashboardLoaded = false;
      await this.fetchDashboardData();
      this.dashboardLoaded = true;
      return;
    }

    if (this.dashboardOptions.length == 0) {
      this.$toast.error("No dashboard assigned to tenant.");
    } else {
      if (
        this.selectedDashboard?.dashboard_id ==
        process.env.VUE_APP_DEFAULT_DASHBOARD_KEY
      ) {
        this.dashboardLoaded = false;
        await this.fetchDashboardData();
        this.dashboardLoaded = true;
      } else {
        this.dashboardLoaded = false;
        await this.getQsDashboardUrl();
      }
    }
  },
  computed: {
    ...mapGetters(["getTenantId"]),
    cardData() {
      if (!this.selectedCard) return null;
      return {
        chart: {
          name: "Some data",
          data: this.caseCount.map((el) => ({
            name: el.status_name,
            value: el.cases_count,
            color: el.status_color,
          })),
        },
        table: {
          title: this.selectedCard.title,
          subtitle: "Entity information",
          data: [],
        },
        sideLegend: [
          { label: "No Data", color: "#969FAA" },
          { label: "Started", color: "#05BEF9" },
          { label: "In Progress", color: "#05BEF9" },
          { label: "Action Needed", color: "#F0AD00" },
          { label: "On Hold", color: "#F0AD00" },
          { label: "Past SLA", color: "#F81A77" },
          { label: "Complete", color: "#57B54F" },
          { label: "Flagged", color: "#FF0000" },
        ],
        checksTimeline: [],
      };
    },
    tableLargeData() {
      return {
        candidateView: {
          columns: [
            {
              label: "Case ID",
              field: "case_id",
              query_key: "case_id",
              sortable: true,
              thClass: "nowrap",
              tdClass: "text-center",
              config: {
                type: "link",
                sortable_key: "case_id",
              },
              // prefetchedOptions: this.allTeams.map((team) => ({ ...team, name: team.title })),
            },
            {
              label: "Client",
              field: "client_name",
              query_key: "client",
              sortable: true,
              tdClass: "text-center capitalize",
              config: {
                sortable_key: "client_name",
              },
            },
            {
              label: "Requestor",
              field: "created_by_name",
              query_key: "created_by",
              sortable: true,
              config: {
                sortable_key: "created_by_name",
              },
            },
            {
              label: "Invitation Date",
              field: "created_at",
              query_key: "from_created_at",
              field_type: "date",
              sortable: true,
              config: {
                sortable_key: "created_at",
              },
            },
            {
              label: "Case Deadline",
              field: "due_date",
              query_key: "from_due_date",
              field_type: "date",
              sortable: true,
              config: {
                sortable_key: "due_date",
              },
            },
            {
              label: "Allocated Group",
              field: "teams",
              query_key: "teams",
              sortable: true,
              config: {
                type: "team_allocation",
                units: "groups",
                isAllowed: this.checkPermission("case.allocate"),
                // filterOptions: {
                //   enabled: false, // enable filter for this column
                // },
              },
              prefetchedOptions: this.allTeams.map((team) => ({
                ...team,
                name: team.title,
              })),
            },
            {
              label: "Allocated Users",
              field: "users",
              query_key: "users",
              sortable: true,
              config: {
                type: "user_allocation",
                units: "users",
                isAllowed: this.checkPermission("case.allocate"),
                // filterOptions: {
                //   enabled: false, // enable filter for this column
                // },
              },
              prefetchedOptions: this.allUsers.map((user) => ({
                ...user,
                name: user.user_name,
              })),
            },
            {
              label: "Subject form Status",
              field: "form_status",
              query_key: "form_status",
              width: "10rem",
              sortable: false,
              prefetchedOptions: this.allFormStatusOptions,
            },
            {
              label: "First Name",
              field: "candidate_first_name",
              query_key: "first_name",
              sortable: true,
              config: {
                sortable_key: "candidate_first_name",
              },
            },
            {
              label: "Surname",
              field: "candidate_last_name",
              query_key: "last_name",
              sortable: true,
              config: {
                sortable_key: "candidate_last_name",
              },
            },
            {
              label: "Package",
              field: "screening_package_name",
              query_key: "package",
              sortable: true,
              tdClass: "text-center uppercase",
              config: {
                sortable_key: "screening_package",
              },
            },
            {
              label: "Education Check",
              field: "education_references",
              sortable: false,
              config: {
                type: "indicators",
                // icon: 'https://cdn.sstatic.net/Img/favicons-sprite16.png?v=03de105488a8',
                filterOptions: {
                  enabled: false, // enable filter for this column
                },
              },
            },
            {
              label: "Employment Check",
              field: "employment_references",
              sortable: false,
              config: {
                type: "indicators",
                // icon: 'https://cdn.sstatic.net/Img/favicons-sprite16.png?v=03de105488a8',
              },
              filterOptions: {
                enabled: false, // enable filter for this column
              },
            },
            {
              label: "Checks",
              field: "other_references",
              sortable: false,
              filterOptions: {
                enabled: false, // enable filter for this column
              },
              config: {
                type: "indicators",
                // icon: 'https://cdn.sstatic.net/Img/favicons-sprite16.png?v=03de105488a8',
              },
            },
            {
              label: "Case Status",
              field: "case_status",
              query_key: "case_status",
              sortable: false,
              tdClass: "vgt-center-align",
              config: {
                type: "status",
              },
              prefetchedOptions: this.allCaseStatusOptions,
              // allFormStatusOptions
            },
            //   column.filterOptions.filterDropdownItems
            {
              label: "Phone",
              field: "candidate_phone",
              query_key: "candidate_phone",
              sortable: true,
              config: {
                sortable_key: "candidate_phone",
              },
            },
            {
              label: "Email",
              field: "candidate_email",
              query_key: "candidate_email",
              sortable: true,
              config: {
                sortable_key: "candidate_email",
              },
            },
            {
              label: "Company Number",
              field: "company_number",
              query_key: "company_number",
              sortable: true,
              config: {
                sortable_key: "company_number",
              },
            },
            {
              label: "Company jursidiction",
              field: "company_jurisdiction",
              query_key: "company_jurisdiction",
              sortable: true,
              config: {
                sortable_key: "candidate_email",
              },
            },
          ]
            // add isLoading prop for columns:
            .map((el) => ({
              ...el,
              config: {
                ...el.config,
                isLoading: !!this.searchLoading.candidate[el.field],
              },
            })),
          // rows: this.candidateViewList,
          rows: this.filteredData.candidateList || [],
        },
        checkView: {
          columns: [
            {
              label: "Case ID",
              field: "case_id",
              thClass: "nowrap",
              query_key: "case_id",
              sortable: true,
              config: {
                type: "link",
                sortable_key: "case_id",
              },
              filterOptions: {
                styleClass: "class1", // class to be added to the parent th element
                enabled: true, // enable filter for this column
              },
            },
            {
              label: "Client",
              field: "client_name",
              query_key: "client",
              sortable: true,
              config: {
                sortable_key: "client_name",
              },
            },
            {
              label: "Requestor",
              field: "created_by_name",
              query_key: "created_by",
              sortable: true,
              config: {
                sortable_key: "created_by_name",
              },
            },
            {
              label: "Invitation Date",
              field: "created_at",
              query_key: "from_created_at",
              sortable: true,
              field_type: "date",
              config: {
                sortable_key: "created_at",
              },
            },
            {
              label: "Case Deadline",
              field: "due_date",
              query_key: "from_due_date",
              sortable: true,
              field_type: "date",
              config: {
                sortable_key: "due_date",
              },
            },
            {
              label: "Package",
              field: "screening_package_name",
              query_key: "package",
              sortable: true,
              config: {
                sortable_key: "screening_package_name",
              },
            },
            {
              label: "Check",
              field: "check_title",
              query_key: "check",
              sortable: true,
              config: {
                sortable_key: "check_title",
              },
            },
            {
              label: "Check Status",
              field: "check_status",
              query_key: "check_status",
              sortable: true,
              tdClass: "vgt-center-align",
              config: {
                type: "status",
                sortable_key: "check_status",
              },
              prefetchedOptions: this.allCheckStatusOptions,
              // allFormStatusOptions
            },
            {
              label: "Alert",
              field: "alert",
              query_key: "alert",
              sortable: false,
            },
            {
              label: "First Name",
              field: "candidate_first_name",
              query_key: "first_name",
              sortable: true,
              config: {
                sortable_key: "candidate_first_name",
              },
            },
            {
              label: "Surname",
              field: "candidate_last_name",
              query_key: "last_name",
              sortable: true,
              config: {
                sortable_key: "candidate_last_name",
              },
            },
            // {
            // 	label: 'Education',
            // 	field: 'education',
            // 	tdClass: 'vgt-center-align',
            // 	config: {
            // 		type: 'indicators',
            // 		icon: 'https://cdn.sstatic.net/Img/favicons-sprite16.png?v=03de105488a8',
            // 	},
            // },
            {
              label: "Referee Email ID",
              field: "referee_email",
              query_key: "referee_email",
              sortable: true,
              config: {
                sortable_key: "referee_email",
              },
            },
            {
              label: "Referee Phone",
              field: "referee_phone",
              query_key: "referee_phone",
              sortable: true,
              config: {
                sortable_key: "referee_phone",
              },
            },
          ]
            // add isLoading prop for columns:
            .map((el) => ({
              ...el,
              config: {
                ...el.config,
                isLoading: !!this.searchLoading.check[el.field],
              },
            })),
          rows: this.filteredData.checkViewList || [],
        },
        taskView: {
          columns: [
            {
              label: "Case ID",
              field: "visible_case_id",
              query_key: "visible_case_id",
              sortable: true,
              config: {
                filter: true,
                type: "link",
                sortable_key: "case_id",
              },
            },
            {
              label: "Subject First Name",
              field: "candidate_first_name.label",
              query_key: "candidate_first_name",
              width: "10rem",
              sortable: true,
              config: {
                filter: true,
                sortable_key: "first_name",
              },
            },
            {
              label: "Subject Last Name",
              field: "candidate_last_name.label",
              query_key: "candidate_last_name",
              sortable: true,
              config: {
                filter: true,
                sortable_key: "last_name",
              },
            },
            {
              label: "Activity Type",
              field: "activity_type.label",
              query_key: "activity_type",
              sortable: false,
              config: {
                filter: true,
              },
            },
            {
              label: "Status",
              field: "status.label",
              query_key: "status",
              sortable: false,
              config: {
                filter: true,
              },
            },
            {
              label: "Due Date",
              field: "due_date.label",
              query_key: "due_date.label",
              sortable: true,
              field_type: "date",
              config: {
                filter: true,
                filter_type: "date",
                query_keys: {
                  start: "due_date_start_date",
                  end: "due_date_end_date",
                },
                sortable_key: "due_date",
              },
            },
            {
              label: "Allocated Group",
              field: "teams",
              query_key: "teams",
              sortable: true,
              config: {
                type: "team_allocation",
                units: "groups",
                isAllowed: this.checkPermission("case.allocate"),
                sortable_key: "title",
                // filterOptions: {
                //   enabled: false, // enable filter for this column
                // },
              },
              prefetchedOptions: this.allTeams.map((team) => ({
                ...team,
                name: team.title,
              })),
            },
            {
              label: "Allocated Users",
              field: "users",
              query_key: "users",
              sortable: true,
              config: {
                type: "user_allocation",
                units: "users",
                sortable_key: "title",
                isAllowed: this.checkPermission("case.allocate"),
                // filterOptions: {
                //   enabled: false, // enable filter for this column
                // },
              },
              prefetchedOptions: this.allUsers.map((user) => ({
                ...user,
                name: user.user_name,
              })),
            },
            {
              label: "Assignee Role",
              field: "assignee_role.label",
              query_key: "assignee_role",
              sortable: false,
              config: {
                filter: true,
              },
            },
            {
              label: "Assignee Name",
              field: "assignee",
              query_key: "assignee",
              sortable: true,
              config: {
                filter: true,
                sortable_key: "assignee_name",
              },
            },
            {
              label: "Check",
              field: "check",
              query_key: "check",
              sortable: false,
              config: {
                filter: true,
              },
            },
            {
              label: "Title",
              field: "title.label",
              query_key: "title",
              sortable: true,
              config: {
                filter: true,
                type: "rich_text",
                sortable_key: "title",
              },
              tdClass: "truncate max-w-sm",
            },
          ].map((el) => ({
            ...el,
            config: {
              ...el.config,
              isLoading: !!this.searchLoading.task[el.field],
            },
          })),
          rows: this.filteredData.taskViewList || [],
        },
      };
    },
    filteredData() {
      //   let result = {};
      //   const checks = this.columnFilters.check;
      //   const candidates = this.columnFilters.candidate;
      //   let checkViewOgList = JSON.parse(JSON.stringify(this.checkViewList));
      //   let candidateViewOgList = JSON.parse(
      //     JSON.stringify(this.candidateViewList)
      //   );
      //   let checksList, candidateList;
      //   if (checks && Object.keys(checks).length) {
      //     checksList = this.checkViewList.length
      //       ? checkViewOgList.filter((chk) => {
      //           return Object.keys(checks).some((fil) => {
      //             return checks[fil].includes(chk[fil]);
      //           });
      //         })
      //       : [];
      //     result.checksList = checksList;
      //   } else {
      //     result.checksList = this.checkViewList;
      //   }
      //   if (candidates && Object.keys(candidates).length) {
      //     candidateList = this.checkViewList.length
      //       ? candidateViewOgList.filter((chk) => {
      //           return Object.keys(candidates).some((fil) => {
      //             return candidates[fil].includes(chk[fil]);
      //           });
      //         })
      //       : [];
      //     result.candidateList = candidateList;
      //   } else {
      //     result.candidateList = this.candidateViewList;
      //   }
      return {
        candidateList: this.candidateViewList,
        checksList: this.checkViewList,
        taskViewList: this.taskViewList,
      };
    },
    columnFilterOptions() {
      return {
        check: this.getFilterOptions(
          this.tableLargeData.checkView.columns,
          this.checkViewList
        ),
        candidate: this.getFilterOptions(
          this.tableLargeData.candidateView.columns,
          this.candidateViewList
        ),
        task: this.getFilterOptions(
          this.tableLargeData.taskView.columns,
          this.taskViewList,
          "task"
        ),
      };
    },
    getPaginationOptions() {
      const selected = this.fakeData.tabs.list.find(
        (tab) => tab.id === this.fakeData.tabs.selected
      );
      return {
        ...this.paginationOptions,
        perPage: this.serverParams[selected.key].perPage,
      };
    },
    allUserOptions() {
      const value = this.globalUsers.map((el) => el.id);
      const selected = this.allUsers.filter((el) => value.includes(el.id));
      const non_selected = this.allUsers.filter((el) => !value.includes(el.id));
      return [...selected, ...non_selected];
    },
    allTeamOptions() {
      const value = this.globalGroups.map((el) => el.id);
      const selected = this.allTeams.filter((el) => value.includes(el.id));
      const non_selected = this.allTeams.filter((el) => !value.includes(el.id));
      return [...selected, ...non_selected];
    },
    allClientOptions() {
      const value = this.globalClients.map((el) => el.id);
      const selected = this.allClients.filter((el) => value.includes(el.id));
      const non_selected = this.allClients.filter(
        (el) => !value.includes(el.id)
      );
      return [...selected, ...non_selected];
    },
  },
  methods: {
    FormatDateNew,
    checkPermission,
    ...mapMutations(["SET_CASE_DETAILS"]),
    async fetchDashboardData() {
      await this.getCaseStatusCount();
      // await this.getCheckLists();
      await this.getCaseStatus();
      if (this.caseStatusCard.length) this.selectCard(this.caseStatusCard[0]);
      await this.getCandidateViews();
      await this.fetchAllStatusOptions();
      await this.fetchAllUsers();
      await this.fetchAllTeams();
      await this.fetchAllClients();
      await this.fetchAllFormStatus();
    },

    async selectDashboard() {
      console.log("selected dashboard", this.selectedDashboard);
      if (
        this.selectedDashboard?.dashboard_id !=
        process.env.VUE_APP_DEFAULT_DASHBOARD_KEY
      ) {
        this.dashboardLoaded = false;
        await this.getQsDashboardUrl();
      } else if (
        this.selectedDashboard?.dashboard_id ==
        process.env.VUE_APP_DEFAULT_DASHBOARD_KEY
      ) {
        this.dashboardLoaded = false;
        await this.fetchDashboardData();
        this.dashboardLoaded = true;
        this.dashboardUrl = "";
      }
    },

    async getTenantDashboards() {
      try {
        const { data } = await axios.get("/dashboard/assigned-dashboards");

        this.dashboardOptions = data.dashboards.map((dashboard) => {
          let bbDashboard = dashboard;
          if (dashboard.name.includes("BB_")) {
            bbDashboard.labelName = dashboard.name.split("_")[1];
          } else {
            bbDashboard.labelName = dashboard.name;
          }

          bbDashboard.name = dashboard.name;
          return bbDashboard;
        });
        this.selectedDashboard = this.dashboardOptions[0];
      } catch (err) {
        console.log(err);
      }
    },

    async getQsDashboardUrl() {
      if (this.selectedDashboard.name.split("_")[0] == "BB") {
        this.dashboardUrl = this.selectedDashboard.name.split("_")[2];
        this.dashboardLoaded = true;
        return this.dashboardUrl;
      } else {
        try {
          const { data } = await axios.get(
            `/dashboard/qs-dashboard/${this.selectedDashboard.dashboard_id}`
          );
          this.dashboardLoaded = true;
          this.dashboardUrl = data.url;
          return data.url;
        } catch (err) {
          this.dashboardLoaded = false;
          return "";
        }
      }
    },

    async getAllRouterLinks() {
      let rows = [];
      const formatDate = (date) =>
        DateTime.fromISO(date).toFormat("dd MMM yyyy");
      for (let index in this.tableLargeData.candidateView.rows) {
        const row = this.tableLargeData.candidateView.rows[index];
        row.due_date = row?.due_date ? formatDate(row?.due_date) : null;
        row.created_at = row?.created_at ? formatDate(row?.created_at) : null;
        if (row.automated) {
          await this.getRouterLink(row).then((link) => {
            rows.push({ ...row, link });
          });
        } else rows.push(row);
      }
      this.tableLargeData.candidateView.rows = rows;
    },

    selectCard(card) {
      console.log(">>>", card);
      // this.selectedTileFilter = []
      this.selectedCard = card;
      this.getCaseStatusDetails(card.id);
      if (this.$refs["scrollContainer"]) {
        this.$refs["scrollContainer"]?.handleScroll();
      }
    },
    async selectTab(id) {
      this.columnFilters = {};
      if (this.fakeData.tabs.selected !== id) this.sortParams = null;
      this.fakeData.tabs.selected = id;
      switch (id) {
        case 1:
          this.serverParams["candidate"].page = 1;
          await this.getCandidateViews(this.candidateViewParams);
          break;
        case 2:
          this.serverParams["check"].page = 1;
          await this.getCheckLists(this.candidateViewParams);
          break;
        case 3:
          this.serverParams["task"].page = 1;
          await this.getTaskView(this.candidateViewParams);
          break;

        default:
          break;
      }
    },

    /**
     * params: query params for api
     * description: to fetch all case list for case list table
     */
    async getCandidateViews(params = null) {
      this.isLoading = true;
      let filterParams = {
        tenant_id: this.$store.getters.getTenantId,
        global_users: this.globalUsers.map((el) => el.id),
        global_groups: this.globalGroups.map((el) => el.id),
        global_clients: this.globalClients.map((el) => el.id),
      };
      const getFilterValue = (key) => {
        if (["form_status", "case_status", "teams", "users"].includes(key)) {
          return Array.isArray(this.columnFilters.candidate[key])
            ? this.columnFilters.candidate[key]?.map((filter) => filter?.["id"])
            : this.columnFilters.candidate[key]["id"];
        } else
          return Array.isArray(this.columnFilters.candidate[key])
            ? this.columnFilters.candidate[key]?.map(
                (filter) => filter?.["name"]
              )
            : this.columnFilters.candidate[key]["name"];
      };
      if (this.columnFilters.candidate)
        Object.keys(this.columnFilters.candidate).map((key) => {
          // if that key exist
          // add it to filter else we'll delete it
          // so it wont make empty strings in parameters
          filterParams[key] = this.columnFilters.candidate[key]
            ? getFilterValue(key)
            : delete filterParams[key];
        });
      if (params) {
        Object.keys(params).forEach((key) => {
          // if (Array.isArray(params[key])) {
          // params[key].map((ele) => {
          filterParams[key] = params[key];
          // });
          // } else qParams.set(key, params[key])
        });
      }
      let { data, error } = await axios.post(`/dashboard/tenant`, {
        ...filterParams,
        ...this.sortParams,
        page: this.serverParams.candidate.page,
        count: this.serverParams.candidate.perPage,
        // events:[this.caseEventDetails.id]
      });

      if (error) {
        console.log(error, "getCandidateViews >>>");
      } else {
        this.candidatesTotalPage = data.page;
        this.candidateViewList = data.data;
        await this.getAllRouterLinks();
      }
      this.isLoading = false;
    },

    /**
     * params: query params for api
     * description: to fetch all check list for check list table
     */
    async getCheckLists(params = null) {
      this.isLoadingChecks = true;
      let filterParams = {
        global_users: this.globalUsers.map((el) => el.id),
        global_groups: this.globalGroups.map((el) => el.id),
        global_clients: this.globalClients.map((el) => el.id),
      };
      const getFilterValue = (key) => {
        if (["form_status", "case_status", "check_status"].includes(key)) {
          return Array.isArray(this.columnFilters.check[key])
            ? this.columnFilters.check[key]?.map((filter) => filter?.["id"])
            : this.columnFilters.check[key]["id"];
        } else
          return Array.isArray(this.columnFilters.check[key])
            ? this.columnFilters.check[key]?.map((filter) => filter?.["name"])
            : this.columnFilters.check[key]["name"];
      };
      if (params) {
        Object.keys(params).forEach((key) => {
          // if (Array.isArray(params[key])) {
          // params[key].map((ele) => {
          filterParams[key] = params[key];
          // });
          // } else qParams.set(key, params[key])
        });
      }
      if (this.columnFilters.check)
        Object.keys(this.columnFilters.check).map((key) => {
          // if that key exist
          // add it to filter else we'll delete it
          // so it wont make empty strings in parameters
          filterParams[key] = this.columnFilters.check[key]
            ? getFilterValue(key)
            : delete filterParams[key];
        });
      try {
        const { data } = await axios.post(`/dashboard/checks`, {
          ...filterParams,
          ...this.sortParams,
          page: this.serverParams.check.page,
          count: this.serverParams.check.perPage,
        });
        // format date into YYYY-MM-DD
        // const formatDate = (date) =>
        //   DateTime.fromISO(date).toFormat("dd MMM yyyy");
        this.checkViewList = data.data.map((el) => ({
          ...el,
          created_at: this.FormatDateNew(new Date(el.created_at)),
          due_date: this.FormatDateNew(new Date(el.due_date)),
        }));
        if (this.columnFilters.check) {
          this.columnFilters?.check?.check_status?.map((item) => {
            if (
              item.name == "Started(Check)" ||
              item.name == "In Progress(Check)"
            ) {
              this.checkViewList.forEach((data) => {
                if (data.check_status == "Action Needed") {
                  data.check_status = item.name.replace("(Check)", "").trim();
                }
              });
            }
          });
        }
        this.checksTotalPages = data.page;
      } catch (error) {
        console.log("error.message :>> ", error.message);
      }
      this.isLoadingChecks = false;
    },

    formatDate(date) {
      if (date) {
        return DateTime.fromISO(date).toFormat("yyyy-LL-dd");
      } else {
        return null;
      }
    },

    async getTaskView() {
      const { task } = this.columnFilters;
      this.loadingStates.taskView = true;
      try {
        // DateTime.fromISO(date).toFormat("yyyy-LL-dd")
        let acknowledged = task?.status
          ? task.status[0].value !== "Unacknowledged"
          : null;
        let payload = {
          global_users: this.globalUsers.map((el) => el.id),
          global_groups: this.globalGroups.map((el) => el.id),
          global_clients: this.globalClients.map((el) => el.id),
          activity_type: Array.isArray(task?.activity_type)
            ? task?.activity_type?.map((el) => el?.value) || []
            : task?.activity_type?.value || null,
          assignee_role: Array.isArray(task?.assignee_role)
            ? task?.assignee_role?.map((el) => el?.value) || []
            : task?.assignee_role?.value || null,
          assignee: Array.isArray(task?.assignee)
            ? task?.assignee?.map((el) => el?.value) || []
            : task?.assignee?.value || null,
          acknowledged,
          candidate_first_name: Array.isArray(task?.candidate_first_name)
            ? task?.candidate_first_name?.map((el) => el?.value) || []
            : task?.candidate_first_name?.value || null,
          candidate_last_name: Array.isArray(task?.candidate_last_name)
            ? task?.candidate_last_name?.map((el) => el?.value) || []
            : task?.candidate_last_name?.value || null,
          visible_case_id: Array.isArray(task?.visible_case_id)
            ? task?.visible_case_id?.map((el) => el?.value) || []
            : task?.visible_case_id?.value || null,
          event_id: this.candidateViewParams?.events || [],
          due_date_start_date: task?.due_date_start_date?.name
            ? this.formatDate(task?.due_date_start_date?.name)
            : null,
          due_date_end_date: task?.due_date_end_date?.name
            ? this.formatDate(task?.due_date_end_date?.name)
            : null,
          check: Array.isArray(task?.check)
            ? task?.check?.map((el) => el?.value) || []
            : task?.check?.value || null,
          title: Array.isArray(task?.title)
            ? task?.title?.map((el) => el?.value) || []
            : task?.title?.value || null,
          page: this.serverParams.task.page,
          count: this.serverParams.task.perPage,
          users: Array.isArray(task?.users)
            ? task?.users?.map((el) => el?.id) || []
            : task?.users?.value || null,
          groups: Array.isArray(task?.teams)
            ? task?.teams?.map((el) => el?.id) || []
            : task?.teams?.value || null,
          ...this.sortParams,
        };
        const { data } = await axios.post(
          `dashboard-notifications/tenant`,
          payload
        );
        this.taskViewList = data?.data?.data.map((el) => ({
          ...el,
          work_allocation: el.case_allocation,
        }));
        const FormateDateRow = (date) =>
          date
            ? DateTime.fromISO(date).setZone("UTC").toFormat("dd MMM yyyy")
            : "";
        this.taskViewList = this.taskViewList.map((ele) => ({
          ...ele,
          due_date: {
            value: FormateDateRow(ele.due_date.value),
            label: FormateDateRow(ele.due_date.label),
          },
        }));
        this.tasksTotalPages = {
          total: data?.data?.page?.total_count ?? 0,
          total_pages: data?.data?.page?.total_pages ?? 0,
        };
        this.due_date = payload.due_date_start_date
          ? this.FormatDateNew(new Date(payload.due_date_start_date)) +
            "~" +
            this.FormatDateNew(new Date(payload.due_date_end_date))
          : "select date";
      } catch (error) {
        console.log(">>>> error", error);
      }
      this.loadingStates.taskView = false;
    },
    // handle link type cell => click
    // async handleCaseClick(caseId) {
    // },

    async getCaseStatusCount() {
      let filterParams = {
        global_users: this.globalUsers.map((el) => el.id),
        global_groups: this.globalGroups.map((el) => el.id),
        global_clients: this.globalClients.map((el) => el.id),
        tenant_id: this.$store.getters.getTenantId,
      };
      try {
        const { data } = await axios.post(
          `dashboard/case-status-count`,
          filterParams
        );
        this.caseCount = data?.data;
      } catch (error) {
        console.log("error :>> ", error);
      }
    },

    async fetchAllStatusOptions() {
      try {
        const response = await axios(`case/status`);
        this.allCaseStatusOptions = response.data.data?.case_status;
        const check_status = response.data.data?.check_status?.map((el) => ({
          ...el,
          name: el.name.concat("(Check)"),
        }));
        const referee_status = response.data.data?.ref_status?.map((el) => ({
          ...el,
          name: el.name.concat("(Referee)"),
        }));
        this.allCheckStatusOptions = check_status?.concat(referee_status);
      } catch (error) {
        console.log("error :>> ", error);
      }
    },
    async fetchAllFormStatus() {
      try {
        const response = await axios(`case/form-status`);
        this.allFormStatusOptions = response.data.data;
      } catch (error) {
        console.log("error :>> ", error);
      }
    },

    async getRouterLink(data) {
      try {
        const automatedCaseData = await axios.get(
          `/integrations/neotas/${data.id}`
        );
        return {
          name: "results-details",
          params: {
            tool: "political-exposure",
            neotasCase:
              automatedCaseData?.data?.immediate_resp_success_json?.case_id,
          },
          query: { delviumCase: automatedCaseData?.data?.case_id },
        };
      } catch (error) {
        console.log(error, "getRouterLink >>>> ERROR");
      }
    },

    async handleStatusClick(row_data) {
      const case_id = row_data.row.id;
      this.statusModalTitle =
        row_data.row.case_id.toUpperCase() +
        "        " /* + row_data.row.screening_package_name.toUpperCase() + */ +
        " Status";
      // let fields = ['candidate_first_name', ]

      const today = new Date();
      const lists = [
        "candidate_first_name",
        "candidate_last_name",
        "candidate_email",
        "candidate_phone",
      ];
      const calculateDays = (today, date) =>
        (today.getTime() - new Date(date).getTime()) / (1000 * 3600 * 24);
      Object.keys(this.statusModalTable.row).map((el) => {
        if (lists.includes(el)) {
          this.statusModalTable.row[el] = row_data.row[el] || "-";
        } else if (el === "days_remaining" && row_data.row?.created_at) {
          this.statusModalTable.row[el] =
            Math.ceil(calculateDays(today, row_data.row.created_at)) + "  Days";
        } else if (el === "days_so_far" && row_data.row?.due_date) {
          this.statusModalTable.row[el] =
            Math.ceil(calculateDays(today, row_data.row?.due_date)) + "  Days";
        } else {
          this.statusModalTable.row[el] = "-";
        }
      });
      try {
        const { data } = await axios(`case/${case_id}/status-timeline`);
        if (data)
          this.timelineData.timeline = data.data.map((el) => ({
            date: new Date(el.timestamp),
            orignalDate: el.timestamp,
            title: el.status_name,
            description: "",
            color: el.status_color,
          }));

        const { data: timeline } = await axios(
          `case/${case_id}/checks-timeline`
        );
        this.checksTimeline = timeline.data.map((obj) => {
          let data = {};
          data = {
            header: {
              label: obj.check_name,
              icon: "#",
            },
          };
          data.timeline = obj.timeline.map((el) => ({
            date: new Date(el.from_date),
            orignalDate: el.timestamp,
            title: el.title,
            description: el.status_name,
            color: el.status_color,
          }));
          return data;
        });
        this.$refs["status-modal"].showModal();
      } catch (error) {
        console.log("error :>> ", error);
      }
    },

    handleCloseStatusModal() {
      this.statusModalTitle = " ";
      this.$refs["status-modal"].hideModal();
    },
    // async handleCaseClick(caseData) {
    //     // TODO check if doesn't duplicate functionality from case data fetching
    //     if (caseData && caseData.case_id) {
    //         this.SET_CASE_DETAILS(caseData);
    //         this.$router.push({
    //             name: "Profile Container",
    //             params: { caseid: caseData.case_id },
    //             query: { caseid: caseData.id },
    //         });
    //     }
    // },
    formatFn: (value) => {
      if (value) value = DateTime.fromISO(value).toFormat("dd MMM yyyy");
      else value = "-";
      return value;
    },
    async onSortChange(data, type) {
      const conf = {
        candidate: this.getCandidateViews,
        check: this.getCheckLists,
        task: this.getTaskView,
      };
      if (data && data.length && data[0].type !== "none") {
        data = data[0];
        (this.sortParams = {
          order_key: this.checkSortableKey(data.field, type),
          order_type: data.type,
        }),
          await conf[type](this.candidateViewParams);
      } else {
        this.sortParams = null;
        await conf[type](this.candidateViewParams);
      }
    },
    checkSortableKey(field, type) {
      const columns = {
        check: this.tableLargeData.checkView.columns,
        candidate: this.tableLargeData.candidateView.columns,
        task: this.tableLargeData.taskView.columns,
      };
      return columns[type].find((el) => el.field === field && el.sortable)
        ?.config.sortable_key;
    },

    async onPageChange(data, type, resetPage = false) {
      if (resetPage) {
        this.serverParams[type].page = 1;
      }
      if (type) {
        if (!resetPage) this.serverParams[type].page = data.currentPage;
        this.serverParams[type].perPage = data.currentPerPage;
        try {
          // type === "check" ? this.getCheckLists(this.candidateViewParams) : this.getCandidateViews(this.candidateViewParams);
          const conf = {
            candidate: this.getCandidateViews,
            check: this.getCheckLists,
            task: this.getTaskView,
          };
          if (!resetPage) {
            await this.$nextTick();
            const scrollTarget = document.getElementById(type);
            if (scrollTarget) {
              scrollTarget.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }
          }
          await conf[type](this.candidateViewParams);
        } catch (error) {
          console.log("error fetching table query :>> ", error);
        }
      }
    },
    async onColumnFilters({ columnFilters }, type) {
      // let columnFilters = filter.id
      // Object.keys(columnFilters).forEach((key) => {
      //     if (!columnFilters[key]?.length > 0) delete columnFilters[key];
      // });
      Object.keys(columnFilters).map((key) => {
        if (
          !Array.isArray(columnFilters[key]) &&
          columnFilters[key]?.field_type !== "date"
        )
          columnFilters[key] = [columnFilters[key]];
      });
      this.columnFilters[type] = columnFilters ? { ...columnFilters } : null;
      this.serverParams[type].page = 1;

      const conf = {
        candidate: this.getCandidateViews,
        check: this.getCheckLists,
        task: this.getTaskView,
      };
      await conf[type](this.candidateViewParams);
    },
    async clearFilters(type) {
      this.columnFilters[type] = null;
      this.serverParams[type].page = 1;
      const conf = {
        candidate: this.getCandidateViews,
        check: this.getCheckLists,
        task: this.getTaskView,
      };
      await conf[type](this.candidateViewParams);
    },
    getFilterOptions(columns, rows, type) {
      if (type === "task") {
        return columns.reduce((result, curr) => {
          const fields = curr.field.split(".");
          result[curr.field] = rows
            .filter((row) => {
              return fields?.length === 1
                ? row[curr.field]
                : row[fields[0]][fields[1]];
            })
            .map((row) => {
              let obj = {
                id: uuid.v4(),
                name:
                  fields?.length === 1
                    ? row[curr.field]
                    : row[fields[0]][fields[1]],
                value: row[fields[0]]?.value,
              };
              if (!obj.value) {
                obj.value = obj.name;
              }
              return obj;
            });
          result[curr.field] = uniqBy(result[curr.field], "name");
          // * activity body someitmes content the unparsed html content
          if (result["title.label"])
            result["title.label"] = result["title.label"].map((el) => ({
              ...el,
              name: this.removeHTML(el.name),
            }));
          return result;
        }, {});
      }
      return columns.reduce((result, curr) => {
        result[curr.field] = rows
          .filter((row) => row[curr.field])
          .map((row) => row[curr.field])
          .filter(onlyUnique)
          .map((el) => ({ id: uuid.v4(), name: el }));
        return result;
      }, {});
    },
    async getCaseStatus() {
      this.caseCountLoader = true;
      let filterParams = {
        tenant_id: this.$store.getters.getTenantId,
        global_users: this.globalUsers.map((el) => el.id),
        global_groups: this.globalGroups.map((el) => el.id),
        global_clients: this.globalClients.map((el) => el.id),
      };
      try {
        const { data } = await axios.post(`dashboard/events`, filterParams);
        this.caseStatusCard = data?.data.map((el) => ({
          title: el.name,
          color: el.color,
          id: el.id,
          value: el.value,
        }));
        //  :title="card.label"
        // :value="card.value"
        // :color="card.color"
      } catch (error) {
        console.log("error :>> ", error);
      }
      this.caseCountLoader = false;
    },

    async getCaseStatusDetails(event_id) {
      let filterParams = {
        tenant_id: this.$store.getters.getTenantId,
        events_group_id: event_id,
        global_users: this.globalUsers.map((el) => el.id),
        global_groups: this.globalGroups.map((el) => el.id),
        global_clients: this.globalClients.map((el) => el.id),
      };
      try {
        const { data } = await axios.post(
          `dashboard/event-details`,
          filterParams
        );
        this.caseEventDetails = data.data;
        this.caseEventDetails.data = this.caseEventDetails.data.map((ele) => ({
          ...ele,
          label: ele.name,
          selected: false,
        }));
        this.caseEventDetails.data = this.caseEventDetails.data.map(
          (rowData) => {
            if (this.candidateViewParams.events.includes(rowData.id)) {
              rowData.selected = true;
            }
            return rowData;
          }
        );
      } catch (error) {
        console.log("error :>> ", error);
      }
    },
    // delay for searching:
    getSearchOptionsTick(payload, type) {
      if (this.searchTimeout) clearTimeout(this.searchTimeout);
      const delay = 500;
      this.searchTimeout = setTimeout(() => {
        this.getSearchOptions(payload, type);
      }, delay);
    },

    async getSearchOptions(payload, type) {
      // if searching is canceled reset async filter options for the field:
      if (!payload.value) this.$set(this.columnFilterOptionsAsync, type, null);
      else {
        // set 'loading' prop for the column we are searching in:
        this.$set(this.searchLoading[type], payload.column.field, true);

        try {
          const formatDate = (date) =>
            DateTime.fromISO(date).toFormat("dd MMM yyyy");
          let newFilterVal = null;
          if (type === "candidate" || type === "check") {
            const requestData = {
              global_users: this.globalUsers.map((el) => el.id),
              global_groups: this.globalGroups.map((el) => el.id),
              global_clients: this.globalClients.map((el) => el.id),
              page: this.serverParams[type].page,
              count: this.serverParams[type].perPage,
              [payload.column.query_key]: payload.value,
            };

            if (type === "candidate")
              requestData["tenant_id"] = this.$store.getters.getTenantId;

            const getFilterValue = (key) => {
              if (
                [
                  "form_status",
                  "case_status",
                  "teams",
                  "users",
                  "check_status",
                ].includes(key)
              ) {
                return Array.isArray(this.columnFilters[type][key])
                  ? this.columnFilters[type][key]?.map(
                      (filter) => filter?.["id"]
                    )
                  : this.columnFilters[type][key]["id"];
              } else
                return Array.isArray(this.columnFilters[type][key])
                  ? this.columnFilters[type][key]?.map(
                      (filter) => filter?.["name"]
                    )
                  : this.columnFilters[type][key]["name"];
            };
            if (this.columnFilters[type])
              Object.keys(this.columnFilters[type]).map((key) => {
                requestData[key] = this.columnFilters[type][key]
                  ? getFilterValue(key)
                  : delete requestData[key];
              });

            const url =
              type === "candidate" ? "dashboard/tenant" : "/dashboard/checks";
            const { data } = await axios.post(url, requestData);
            newFilterVal = data.data.map((el) => ({
              ...el,
              created_at: formatDate(el.created_at),
              due_date: formatDate(el.due_date),
            }));
          } else if (type === "task") {
            let filters = {};
            if (this.columnFilters[type]) {
              //fetching out the value from the object
              Object.entries(this.columnFilters[type]).forEach(
                ([key, value]) => {
                  let obj = {};
                  obj[key] = Array.isArray(value)
                    ? value.map((filter) => filter.value)
                    : value.value;
                  filters = { ...filters, ...obj };
                }
              );
            }
            const requestData = {
              global_users: this.globalUsers.map((el) => el.id),
              global_groups: this.globalGroups.map((el) => el.id),
              global_clients: this.globalClients.map((el) => el.id),
              page: this.serverParams.task.page,
              count: this.serverParams.task.perPage,
              ...filters,
              [payload.column.query_key]:
                payload.column.field_type === "date"
                  ? formatDate(payload.value)
                  : payload.value,
            };
            const { data } = await axios.post(
              `dashboard-notifications/tenant`,
              requestData
            );
            newFilterVal =
              data?.data?.data.map((el) => ({
                ...el,
                due_date: {
                  value: formatDate(el.due_date.value),
                  label: formatDate(el.due_date.label),
                },
              })) ?? [];
          }
          // set async options:
          const columns = {
            check: this.tableLargeData.checkView.columns,
            candidate: this.tableLargeData.candidateView.columns,
            task: this.tableLargeData.taskView.columns,
          };
          this.$set(
            this.columnFilterOptionsAsync,
            type,
            this.getFilterOptions(columns[type], newFilterVal, type)
          );
        } catch (error) {
          console.log("error :>> ", error);
        }

        this.$set(this.searchLoading[type], payload.column.field, false);
      }
    },
    async handleRowClick({ id, value }) {
      if (this.caseEventDetails.name === "Outstanding Actions") {
        this.selectedTileFilter = value
          ? [...this.selectedTileFilter, id]
          : this.selectedTileFilter.filter((fid) => fid != id);
      }
      this.candidateViewParams = {};
      this.caseEventDetails.data = this.caseEventDetails.data.map((el) =>
        el.id === id ? { ...el, selected: value } : el
      );
      // row is checked
      const key = "events";
      this.caseEventDetails.data.forEach((card) => {
        if (card.selected) {
          // if (this.candidateViewParams) {
          if (this.candidateViewParams[key])
            this.candidateViewParams[key] = [
              ...this.candidateViewParams[key],
              card["id"],
            ];
          else this.candidateViewParams[key] = [card["id"]];
          // });
        }
      });
      // // row is unchecked
      const conf = {
        candidate: this.getCandidateViews,
        check: this.getCheckLists,
        task: this.getTaskView,
      };
      Object.keys(conf).forEach(async (type) => {
        this.serverParams[type].page = 1;
        await conf[type](this.candidateViewParams);
      });
    },
    async onWorkAllocation(rows) {
      this.usersStates = [];
      this.teamsStates = [];
      // this.fetchAllTeams();
      // this.fetchAllUsers();
      this.fetchAllocatedStates("users", rows);
      this.fetchAllocatedStates("teams", rows);
      this.$modal.show("work-allocation", { rows });
    },
    async fetchAllocatedStates(type, rows) {
      let url = "";
      const payload = {
        case_ids: rows.map((row) => ({ id: row.id })),
      };
      this.loadingStates[type] = true;
      if (type === "users") {
        url = "/work-allocation/case/user-state";
      } else {
        this.loadingStates.teams = true;
        url = "/work-allocation/case/group-state";
      }
      try {
        const { data } = await axios.post(url, payload);
        this[`${type}States`] = data?.map((res) => ({ ...res, status: null }));
      } catch (error) {
        console.log(error, "[+] error while fetching teams");
      }
      this.loadingStates[type] = false;
    },
    onCaseDelete(rows) {
      console.info("rows :>> ", rows);
    },
    async fetchAllTeams() {
      this.loadingStates.teams = true;
      try {
        const { data } = await axios.get(`/group/tenant/${this.getTenantId}`);
        this.allTeams = data.map((team) => ({ ...team, status: null }));
      } catch (error) {
        console.log(error, "[+] error while fetching teams");
      }
      this.loadingStates.teams = false;
    },
    async fetchAllUsers() {
      this.loadingStates.users = true;
      try {
        const { data } = await axios.get(`/user/tenant/${this.getTenantId}`);
        this.allUsers = data.map((user) => ({ ...user, status: null }));
      } catch (error) {
        console.log(error, "[+] error while fetching users");
      }
      this.loadingStates.users = false;
    },
    async fetchAllClients() {
      this.loadingStates.clients = true;
      try {
        const { data } = await axios.get(`/client/tenant/${this.getTenantId}`);
        this.allClients = data?.data.map((user) => ({ ...user, status: null }));
      } catch (error) {
        console.log(error, "[+] error while fetching users");
      }
      this.loadingStates.clients = false;
    },
    onWorkAllocateChange({ index, value, type }) {
      this[type][index].status =
        this[type][index].status === value ? null : value;
    },
    async onWorkAllocationSave({ cases, type }) {
      //type: team or user
      let url = "";
      let payload = {
        case_ids: cases.map((cas) => ({ id: cas.id })),
      };
      if (type === "team") {
        url = "/work-allocation/case/tenant-group";
        payload.add_groups = this.teamsStates
          .filter((user) => user.status === "add")
          .map((elem) => elem.id);
        payload.remove_groups = this.teamsStates
          .filter((user) => user.status === "remove")
          .map((elem) => elem.id);
      } else {
        url = "/work-allocation/case/tenant-user";
        payload.add_users = this.usersStates
          .filter((user) => user.status === "add")
          .map((elem) => elem.id);
        payload.remove_users = this.usersStates
          .filter((user) => user.status === "remove")
          .map((elem) => elem.id);
      }
      try {
        await axios.post(url, payload);
        this.$toast.success("Saved Successfully!");
      } catch (error) {
        this.$toast.error("Saving Failed!");
      }
    },
    async fetchAllocationList(caseId, type) {
      this.loadingStates.getAllocation = true;
      const url = `/work-allocation/case/${caseId}/${type}`;
      try {
        const { data } = await axios.get(url);
        this.allocatedResources = data?.filter((res) => res.allotted) ?? [];
      } catch (error) {
        console.log(error, "[+] error while fetch allocation list");
      }
      this.loadingStates.getAllocation = false;
    },
    async onAllocation({ row, type }, caseType) {
      this.allocatedResources = [];
      this.$modal.show("allocation-list", { type, row });
      // task view use case_id
      if (caseType === "task") {
        await this.fetchAllocationList(row.case_id, type);
      } else {
        await this.fetchAllocationList(row.id, type);
      }
    },
    async removeAllocation({ resource, type, row }) {
      const selected = this.fakeData.tabs.list.find(
        (tab) => tab.id === this.fakeData.tabs.selected
      );
      this.loadingStates.getAllocation = true;
      let url = "";
      let payload = {};
      if (selected && selected.key === "task") {
        payload = {
          case_ids: [{ id: row.case_id }],
        };
      } else {
        payload = {
          case_ids: [{ id: row.id }],
          // events: [this.caseEventDetails.id],
        };
      }
      if (type === "groups") {
        url = "/work-allocation/case/tenant-group";
        payload.add_groups = [];
        payload.remove_groups = [resource.id];
        // payload.events = [this.caseEventDetails.id]
      } else {
        url = "/work-allocation/case/tenant-user";
        payload.add_users = [];
        payload.remove_users = [resource.id];
        // payload.events = [this.caseEventDetails.id]
      }
      // isUserGroup = true
      try {
        await axios.post(url, payload);
        if (selected && selected.key === "task") {
          await this.fetchAllocationList(row.case_id, type);
          await this.getTaskView();
        } else {
          await this.fetchAllocationList(row.id, type);
          this.getCandidateViews({ events: [...this.selectedTileFilter] });
        }

        this.$toast.success("Removed Successfully!");
      } catch (error) {
        this.$toast.error("Removing Failed!");
      }
      this.loadingStates.getAllocation = false;
    },
    async onAllocationReset({ type, rows }) {
      await this.fetchAllocatedStates(type, rows);
    },
    removeHTML(str) {
      let tmp = document.createElement("DIV");
      tmp.innerHTML = str;
      return tmp.textContent || tmp.innerText || "";
    },
    checkData(value) {
      let item = value.customData;
      if (
        item?.activity_type?.value === "case_task" ||
        item?.activity_type?.value === "email"
      ) {
        return this.removeHTML(item.title.label);
      } else {
        return item.title.label;
      }
    },
    handleCaseClick(customData, sectionType) {
      let route = null;
      if (sectionType === "check") {
        route = this.$router.resolve({
          name: "Profile Container",
          params: { caseid: customData.case_id },
          query: {
            caseid: customData.case_uuid,
            check: customData.tenant_check_id,
            type: customData.category,
            clientId: customData.client_id,
          },
        });
      } else if (sectionType === "task") {
        route = this.$router.resolve({
          name: "Profile Container",
          params: { caseid: customData.customData.visible_case_id },
          query: {
            caseid: customData.customData.case_id,
            action: "history",
            clientId: customData.client_id,
          },
          hash: `#${customData.customData.object_id}`,
        });
      } else if (sectionType === "case") {
        route = this.$router.resolve({
          name: "Profile Container",
          params: { caseid: customData.case_id },
          query: {
            caseid: customData.id || customData?.case_uuid,
            clientId: customData.client_id,
          },
        });
      }
      if (route) window.open(route.href);
    },
    async filterDashboard() {
      await this.getCaseStatusCount();
      await this.getCaseStatus();
      if (this.caseStatusCard.length) this.selectCard(this.caseStatusCard[0]);
      this.selectTab(this.fakeData.tabs.selected);
    },

    // Function to adjust the iframe height
    adjustIframeHeight() {
      var iframe = document.getElementById("dashboard-frame");
      if (iframe) {
        iframe.height = Math.max(document.documentElement.scrollHeight) + "px";
      }
    },
  },
};
</script>
<style lang="scss">
.qs-dashboard {
  width: 100px;
}

.filter-dropdown {
  @apply border-transparent bg-white;
}
</style>
<style lang="scss" scoped>
@import "@/assets/mixins/mixins.scss";
::v-deep {
  .button-base {
    @include buttonBase(#fff, #029adb, #029adb);
  }
}

.page1 {
  gap: 0;
  .card-panel {
    padding-bottom: 15px;
    .card {
      &--selected {
        &::after {
          content: "";
          position: absolute;
          width: 10px;
          bottom: -15px;
          width: 0;
          height: 0;
          border-left: 26px solid transparent;
          border-right: 26px solid transparent;
          border-bottom: 28px solid var(--theme-card-background);
          box-sizing: border-box;
          transform: translateX(-50%);
          left: 50%;
        }
      }
    }
  }
  .content {
    // background-color: #fff;
    @apply bg-card-bg;
    // padding: 40px;
    border-radius: 24px;
    .chart {
      height: 375px;
    }
    .chart,
    .table {
      flex: 1 0 40%;
    }
  }
}
</style>
